<template>
  <div class="mb-4">
    <div>
      <v-col cols="12">
        <v-btn
          v-if="!hideActions && !haveRole('ROLE_OFICIAL_CUMPLIMIENTO')"
          class="mr-3"
          :disabled="montos.monto_restante <= 0"
          color="secondary"
          outlined
          @click="openModal(1)"
        >
          <v-icon left>mdi-cash-multiple</v-icon>
          Pago
        </v-btn>
        <!-- <v-btn
          v-if="!hideActions && handleVisibility && !haveRole('ROLE_OFICIAL_CUMPLIMIENTO')"
          class="mr-3"
          color="secondary"
          outlined
          @click="openModal(2)"
        >
          <v-icon left>mdi-file-document-alert-outline</v-icon>
          Multas
        </v-btn> -->
      </v-col>
    </div>
    <div class="mb-5 d-flex flex-wrap justify-center">
        <MetricasPagosComponet :metricasPagos="montos"></MetricasPagosComponet>
    </div>

    <data-table-component
      :headers="headersPagos"
      :items="pagos"
      v-models:select="filtrosPagos.per_page"
      v-models:pagina="filtrosPagos.page"
      :total_registros="filtrosPagos.total_rows"
      @paginar="paginar"
    >
    <template v-slot:[`item.fecha_hora_movimiento`]="{ item }">
        {{ moment(item.fecha_hora_movimiento).format("DD/MM/YYYY") }}
      </template>
      <template v-slot:[`item.monto`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          }).format(item.monto)
        }}
      </template>
      <template v-slot:[`item.otras_retenciones`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          }).format(item.otras_retenciones)
        }}
      </template>
      <template v-slot:[`item.amortizacion_anticipo`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          }).format(item.amortizacion_anticipo)
        }}
      </template>
      <template v-slot:[`item.desembolso`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          }).format(item.desembolso)
        }}
      </template>
      <template v-slot:[`item.estado`]="{ item }" v-if="esVersion2">
        <v-chip label :color="(item.estado_factura ? item.estado_factura.color : 'gray')">
          <span class="white--text text-bold">{{ item.estado_factura ? item.estado_factura.nombre :  "Pendiente" }}</span>
        </v-chip>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editPago(item, 'show')"
              class="mr-2"
              icon
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Ver detalles</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editPago(item, 'edit')"
              icon
              :disabled="item.id_tipo_movimiento == 2"
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="primary">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>
    </data-table-component>
    <ModalRegistrarPagos
      :showModal="showModal"
      @close="showModal = false" 
      @reloadPagos="reloadPagos()"
      :metricasPagos="montos"
      :editarPago="pagoActivo"
    />
    <ModalDetallePagosComponent
      :showModal="showDetailModal"
      @onClose="closeModal"
      :pagoDetail="pagoDetail"
    >
    </ModalDetallePagosComponent>
    <ModalMultasComponent
      @reloadList="reloadPagos"
      :showModal="showModalMulta"
      @onClose="closeModal"
      :metricasMultas="metricasMultas"
    />
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import moment from "moment";
import ModalDetallePagosComponent from "./ModalDetallePagosComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import ModalPagosComponent from "./ModalPagosComponent.vue";
import ModalRegistrarPagos from "./ModalRegistrarPagos.vue";
import MetricasPagosComponet from "./MetricasPagosComponet.vue";
import AppPaginationComponent from "../../../components/AppPaginationComponent.vue";
import ModalMultasComponent from "./liquidarOrden/ModalMultasComponent.vue";
export default {
  name: "ListadoPagosComponent",
  components: {
    ModalPagosComponent,
    MetricasPagosComponet,
    AppPaginationComponent,
    DataTableComponent,
    ModalDetallePagosComponent,
    ModalMultasComponent,
    ModalRegistrarPagos
  },
  props: {
    hideActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pagoActivo:{},
      showModal: false,
      showModalMulta: false,
      tipoRegistro: null,
      showDetailModal: false,
      pagoDetail: {},
      metricasMultas: {},
    };
  },
  computed: {
    ...mapState("seguimientoOrden", [
      "montos",
      "pagos",
      "filtrosPagos",
      "seguimientoContrato",
    ]),
    headersPagos() {
      if (this.hideActions) {
        return this.headers.filter((target) => target.value !== "acciones");
      } else {
        return this.headers;
      }
    },
    handleVisibility() {
      if (this.seguimientoContrato) {
        const { contrato } = this.seguimientoContrato;
        return contrato?.id_forma_contratacion !== 6;
      } else return false;
    },
    esVersion2(){
      return this.seguimientoContrato?.contrato?.version_seguimiento === 2
    },
    headers() {
      return [
        { text: "Fecha", align: "start", value: "fecha_hora_movimiento" },
        { text: "Justificación", align: "start", value: "justificacion" },
        { text: "Tipo", align: "start", value: "tipo_movimiento" },
        ...(this.esVersion2 ? [{ text: "Estado", align: "start", value: "estado" }] : []),
        { text: "Monto ($)", align: "center", value: "monto" },
        { text: "Amortización anticipo", align: "center", value: "amortizacion_anticipo", sortable: false },
        { text: "Otras retenciones", align: "center", value: "otras_retenciones", sortable: false },
        { text: "Desembolso", align: "center", value: "desembolso", sortable: false },
        { text: "Acciones", align: "center", value: "acciones", sortable: false }
      ];
    }
  },
  methods: {
    ...mapMutations("seguimientoOrden", [
      "getPagoEtapa",
      "clearPagoEtapa",
      "getPagoEditable",
      "setMontos",
      "setPagos",
      "setFiltrosPagos",
    ]),
    ...mapActions("seguimientoOrden", ["getPagos"]),
    openModal(tipo) {
      if (tipo === 1) {
        this.showModal = true;
      } else {
        this.showModalMulta = true;
      }
    },
    closeModal() {
      this.showModal = false;
      this.showDetailModal = false;
      this.showModalMulta = false;
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.setFiltrosPagos({
        page: pagina,
        per_page: cantidad_por_pagina,
        id_tipo_movimiento: (this.hideActions) ? 1 : null,
      });

      this.getPagos({
        idContrato: this.$route.params.idContrato,
        filtros: this.filtrosPagos,
      });
    },
    async getMetricasPagos() {
      const { status, data } =
        await this.services.ContratoService.getMontosPagos(
          this.$route.params.idContrato
        );
      if (status === 200) {
        this.setMontos(data);
      }
    },
    async editPago(item, modal) {
      const { status, data } = await this.services.ContratoService.getContratoPago(item.id);
      if (status == 200) {
        let pago = {
          ...data,
          id_metodo_pago: data.detalle?.id_metodo_pago,
          id_institucion_financiera: data.detalle?.id_institucion_financiera,
          numero_cheque: data.detalle?.numero_cheque,
          a_nombre_de: data.detalle?.a_nombre_de,
          fecha_hora: data.fecha_hora_movimiento,
          fecha: new Date(data.fecha_hora_movimiento)
            .toISOString()
            .substr(0, 10),
          hora: moment(data.fecha_hora_movimiento).format("hh:mm"),
          id_etapa: data.id_etapa,
        };
        this.pagoActivo = {...pago}
        this.getPagoEtapa(pago);
        if (modal == "edit") {
          this.getPagoEditable(true);
          this.showModal = true;
        } else {
          this.pagoDetail = data;
          this.showDetailModal = true;
        }
      }
    },
    reloadPagos() {
      this.getPagos({
        idContrato: this.$route.params.idContrato,
        filtros: this.filtrosPagos,
      });
      this.getMetricasPagos();
    },
    async getConsolidadoMultas() {
      const { status, data } =
        await this.services.ContratoService.getConsolidadoMultas(
          this.$route.params.idContrato
        );
      if (status === 200) {
        this.metricasMultas = {
          cantidad_multas: data.cantidad_multas,
          monto_contratado: data.contrato?.monto_adjudicado,
        };
      }
    },
  },
  async created() {

    await this.getPagos({
      idContrato: this.$route.params.idContrato,
      filtros: {
        ...this.filtrosPagos,
        id_tipo_movimiento: (this.hideActions) ? 1 : null,
      },
    });

    await this.getMetricasPagos();
    this.getConsolidadoMultas();
  },
};
</script>

<style lang="scss" scoped></style>
