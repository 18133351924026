<template>
  <Fragment>
    <v-dialog v-model="isOpen" max-width="1200" persistent>
      <div
        style="
          background-color: #ffffff;
          padding: 0 1em 1em;
          position: relative;
          height: inherit;
        "
      >
        <v-container style="position: relative">
          <div>
            <v-card-title class="d-flex align-center">
              <v-icon class="mr-2">mdi-invoice-plus</v-icon>
              <span class="font-weight-bold mr-2">Agregar factura</span>
            </v-card-title>
          </div>
          <div>
            <v-form ref="form" class="mt-4">
              <v-card style="overflow: hidden">
                <v-col cols="12">
                  <v-row
                    class="flex-column mx-0 my-0"
                    style="text-align: center; gap: 10px; align-items: center"
                  >
                    <v-col
                      cols="12"
                      class="mt-4 px-0 py-0 flex-shrink-1 flex-grow-1"
                    >
                      <v-file-input
                        v-model="adjunto"
                        label="PDF"
                        placeholder="Seleccione un PDF"
                        outlined
                        light
                        accept="application/pdf"
                        :error-messages="adjuntoErrors"
                        @change="$v.adjunto.$touch()"
                        @blur="$v.adjunto.$touch()"
                      />
                    </v-col>
                  </v-row>
                  <div class="d-flex justify-end mt-4">
                    <v-btn
                      class="flex-grow-1 flex-shrink-1"
                      @click.stop="cerrarModal"
                      color="secondary"
                      >Cerrar</v-btn
                    >
                    <v-btn
                      class="flex-grow-1 flex-shrink-1 ml-4"
                      color="primary"
                      @click="guardarFactura()"
                      >Guardar</v-btn
                    >
                  </div>
                </v-col>
              </v-card>
            </v-form>
          </div>
        </v-container>
      </div>

      <ConfirmationModalComponent 
        description="¿Desea guardar la factura?"
        :is-open="abrirConfirmacionFactura"
        @confirm="confirmacionGuardarFactura"
        @cancel="abrirConfirmacionFactura = false"
        :isLoading="isLoading"
      />

    </v-dialog>
  </Fragment>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { Validator } from "@/utils/form-validation.js";
import {
  RetryDataLoading,
  ConfirmationModalComponent,
  ProgresiveLoadAutocomplete,
} from "@/components/utils";
import { Fragment } from "vue-fragment";

export default {
  name: "ModalRecepcionFactura",
  emits: ["on-save"],
  components: {
    RetryDataLoading,
    Fragment,
    ConfirmationModalComponent,
    ProgresiveLoadAutocomplete,
  },
  props: {
    isOpen: { type: Boolean },
    isLoading: { type: Boolean },
  },
  validations() {
    return {
      adjunto: { required },
    };
  },
  data: () => ({
    adjunto: null,
    abrirConfirmacionFactura: false,
  }),
  computed: {
    adjuntoErrors() {
      return new Validator(this.$v.adjunto).detect().getResult();
    },
  },
  methods: {
    guardarFactura(){    
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.abrirConfirmacionFactura = true;
    },
    confirmacionGuardarFactura(){
      this.$emit('on-save', this.adjunto)
      this.limpiarFormulario();
    },
    limpiarFormulario() {
      this.adjunto = null;
      this.$v.$reset();
    },
    cerrarModal() {
      this.limpiarFormulario();
      this.$emit("close");
    },
  },
  watch: {
  },
};
</script>