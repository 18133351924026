<template>
  <div>
    <v-dialog
      :max-width="1200"
      v-model="showModal"
      persistent
      @click:outside="closeModal"
    >
      <div
        style="
          background-color: #ffffff;
          padding: 0 1em 2em;
          position: relative;
        "
      >
        <div
          class="py-4"
          style="
            width: 100%;
            background-color: inherit;
            position: sticky;
            top: 0;
            z-index: 10;
          "
        >
          <v-btn
            style="
              min-width: unset !important;
              min-height: unset !important;
              width: 32px !important;
              height: 32px !important;
            "
            class="px-4 py-4"
            @click.stop="closeModal"
            text
            rounded
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </div>
        <div class="mb-4 d-flex justify-space-between align-center">
          <span class="secondary--text font-weight-bold text-h5 ml-4">
            {{ pagoEditable ? "Edición" : "Registro" }} de pago
          </span>
          <div v-if="mostrarDocumentos" class="d-flex">
            <v-btn
              class=""
              color="secondary"
              :loading="documentos.isLoading"
              @click.stop="visualizarDocumentoRecepcion(documentos.data?.factura.adjunto, documentos.data?.factura.disk)"
            >
              <v-icon color="#FFF !important" class="px-2">mdi-invoice-list-outline</v-icon>
              Factura
            </v-btn>
            <v-btn
              class="ml-2"
              color="secondary"
              :loading="documentos.isLoading"
              @click.stop="visualizarDocumentoRecepcion(documentos.data?.acta_recepcion.ruta_archivo, documentos.data?.acta_recepcion.disk)"
            >
              <v-icon color="#FFF !important" class="px-2">mdi-file-eye-outline</v-icon>
              Acta recepción
            </v-btn>
          </div>
        </div>

        <v-form ref="form" class="mt-4">
          <div class="px-1 mt-1">
            <v-select
              label="Recepcion *"
              outlined
              :items="etapasListData"
              :error-messages="recepcionErrors"
              v-model="form.id_recepcion"
              @blur="$v.form.id_recepcion.$touch()"
              @change="montoRecepcion(form.id_recepcion)"
              @input="obtenerDocumentos(form.id_recepcion)"
              :disabled="pagoEditable"
              item-value="id"
              item-text="etapa"
            >
            </v-select>
            <v-row>
              <v-col :cols="mostrarDocumentos && documentos.data?.comprobante_pago.comprobante_pago ? 9 : 12">
                <v-file-input
                  v-model="form.comprobante_pago"
                  label="Comprobante de pago *"
                  placeholder="Seleccione un PDF"
                  outlined
                  light
                  accept="application/pdf"
                  :error-messages="comprobantePagoErrors"
                />
              </v-col>
              <v-col v-if="mostrarDocumentos && documentos.data?.comprobante_pago.comprobante_pago" cols="3" class="d-flex mt-2 mb-2">
                <v-btn
                  color="secondary"
                  :loading="documentos.isLoading"
                  @click.stop="visualizarDocumentoRecepcion(documentos.data?.comprobante_pago.comprobante_pago, documentos.data?.comprobante_pago.disk)"
                >
                  <v-icon color="#FFF !important" class="px-2">mdi-checkbook</v-icon>
                  Comprobante Pago
                </v-btn>
              </v-col>
              <v-col cols="12">
                <div class="mb-6">
                  <vc-date-picker
                    v-model="form.fecha_hora"
                    :popover="config_calendar"
                    mode="dateTime"
                    locale="es"
                  >
                    <template v-slot="{ inputEvents, showPopover }">
                      <span v-on="inputEvents" @click.stop="showPopover()">
                        <v-text-field
                          label="Fecha"
                          placeholder="Seleccione una fecha"
                          class="border roundend focus:outline-none focus:border-blue-300"
                          :value="formatearFecha(form.fecha_hora)"
                          :error-messages="fechaMovimientoErrors"
                          readonly
                          hide-details
                          outlined
                        />
                      </span>
                    </template>
                  </vc-date-picker>
                </div>
                <v-autocomplete
                  label="Tipo de movimiento *"
                  :items="tipoMovimiento.data"
                  item-value="id"
                  item-text="nombre"
                  outlined
                  v-model="form.id_tipo_movimiento"
                  :error-messages="tipoMovimientoErrors"
                  @blur="$v.form.id_tipo_movimiento.$touch()"
                />
                <v-autocomplete
                  label="Forma de pago *"
                  :items="formasPago"
                  item-value="id"
                  item-text="nombre"
                  outlined
                  v-model="form.id_metodo_pago"
                  :error-messages="formaPagoErrors"
                  @blur="$v.form.id_metodo_pago.$touch()"
                />
                <template v-if="esCheque">
                  <v-autocomplete
                    outlined
                    label="Banco"
                    :items="bancos"
                    item-value="id"
                    item-text="nombre"
                    v-model="form.id_institucion_financiera"
                    :error-messages="bancoErrors"
                    @blur="$v.form.id_institucion_financiera.$touch()"
                  >
                  </v-autocomplete>
                  <div>
                    <v-text-field
                      outlined
                      label="Número de cheque"
                      v-model="form.numero_cheque"
                      :error-messages="numChequeErrors"
                      @blur="$v.form.numero_cheque.$touch()"
                    ></v-text-field>
                    <v-text-field
                      outlined
                      label="A nombre de"
                      v-model="form.a_nombre_de"
                      :error-messages="aNombreDeErrors"
                      @blur="$v.form.a_nombre_de.$touch()"
                    ></v-text-field>
                  </div>
                </template>
                <template v-if="esTrasferencia">
                  <div>
                    <v-file-input
                      outlined
                      label="Comprobante"
                      prepend-icon=""
                      append-icon="mdi-paperclip"
                      accept="application/pdf"
                      v-model="form.comprobante"
                      :error-messages="comprobanteErrors"
                      @blur="$v.form.comprobante.$touch()"
                    ></v-file-input>
                  </div>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-center">
                  <div class="text-center mr-3">
                    <p class="text-h4 mb-0">
                      {{ Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 8,
                        }).format(metricasPagos.monto_total)
                      }}
                    </p>
                    <v-card-subtitle class="justify-center my-0 py-0">
                      <p class="my-0 py-0">Monto total</p>
                    </v-card-subtitle>
                    <v-progress-linear
                      color="black"
                      rounded
                      value="100"
                      class="my-0 py-0"
                    ></v-progress-linear>
                  </div>

                  <div class="text-center ml-3">
                    <p class="text-h4 mb-0">
                      {{ Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 8,
                        }).format(metricasPagos.monto_restante)
                      }}
                    </p>
                    <v-card-subtitle class="justify-center my-0 py-0">
                      <p class="my-0 py-0">Monto restante a pagar</p>
                    </v-card-subtitle>
                    <v-progress-linear
                      color="success"
                      rounded
                      value="100"
                      class="my-0 py-0"
                    ></v-progress-linear>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Monto *"
                  outlined
                  type="number"
                  prepend-inner-icon="mdi-currency-usd"
                  v-model="form.monto"
                  :error-messages="montoErrors"
                  @blur="$v.form.monto.$touch()"
                />
                <v-text-field
                  label="Otras retenciones *"
                  outlined
                  type="number"
                  prepend-inner-icon="mdi-currency-usd"
                  v-model="form.retenciones"
                  :error-messages="rentecionesErrors"
                  @blur="$v.form.retenciones.$touch()"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Armotización anticipo *"
                  outlined
                  type="number"
                  prepend-inner-icon="mdi-currency-usd"
                  v-model="form.armotizacion"
                  :error-messages="armotizacionErrors"
                  @blur="$v.form.armotizacion.$touch()"
                />
                <v-text-field
                  label="Desembolso *"
                  outlined
                  type="number"
                  prepend-inner-icon="mdi-currency-usd"
                  v-model="form.desembolso"
                  disabled
                  :error-messages="desembolsoErrors"
                  @blur="$v.form.desembolso.$touch()"
                />
              </v-col>
            </v-row>
            <v-textarea
              label="Justificación *"
              outlined
              maxlength="250"
              v-model="form.justificacion"
              :error-messages="justificacionErrors"
              @blur="$v.form.justificacion.$touch()"
            />
            <div class="d-flex justify-center align-center" style="gap: 16px">
              <v-btn
                @click.stop="closeModal"
                class="flex-grow-1 flex-shrink-1"
                color="secondary"
              >
                Cancelar
              </v-btn>
              <v-btn
                @click.stop="confirmarCrearPago"
                class="flex-grow-1 flex-shrink-1"
                color="primary"
              >
                {{ pagoEditable ? "Actualizar" : "Guardar" }}
              </v-btn>
            </div>
          </div>
        </v-form>
      </div>
    </v-dialog>
    <ConfirmationModalComponent
      :is-open="confirmacionCrearPago"
      :description="`¿Desea ${
        pagoEditable ? 'actualizar' : 'guardar'
      } el pago?`"
      :is-loading="pago.isLoading"
      @cancel="cancelarConfirmacion"
      @confirm="enviarPago"
    />

    <PdfModal
      :isOpen="modalVisualizarDocumento" 
      :source-loadable="visualizarDocumento" 
      @on-visibility-change="handlePdfModalVisibility"
      filename="archivos-documentos"
  />
  </div>
</template>
<script>
import { required, minLength, minValue } from "vuelidate/lib/validators";
import { ConfirmationModalComponent } from "@/components/utils";
import moment from "moment";
import { mapState, mapMutations, mapActions } from "vuex";
import { Validator } from "@/utils/form-validation.js";
import {
  createLoadable,
  setLoadableResponse,
  toggleLoadable,
} from "@/utils/loadable";
import { convertToFormData, removePropsFromObjectIfNil } from "@/utils/data";
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import PdfModal from "@/components/PdfModal.vue";

export default {
  name: "ModalRegistrarPagos",
  components: { ConfirmationModalComponent, MoneyTextFieldComponent, PdfModal },
  props: {
    showModal: Boolean,
    editarPago: { type: Object },
    metricasPagos: { type: Object, default: () => {} },
  },
  validations() {
    let chequeRules = {};
    if (this.esCheque) {
      chequeRules = {
        required,
      };
    }

    let transferenciaRules = {};
    if (this.esTrasferencia) {
      if (this.pagoEditable) {
        transferenciaRules = {};
      } else {
        transferenciaRules = {
          required,
        };
      }
    }

    let adjuntosRules = {};
    if (!this.pagoEditable) {
      adjuntosRules = {
        required,
      };
    }
    return {
      form: {
        justificacion: { required, minLength: minLength(20) },
        fecha_hora: { required },
        id_tipo_movimiento: { required },
        comprobante: transferenciaRules,
        comprobante_pago: adjuntosRules,
        // condiciones_pago: adjuntosRules,
        id_metodo_pago: { required },
        monto: { required, minValue: minValue(0) },
        armotizacion: { required },
        retenciones: { required },
        desembolso: { required },
        id_recepcion: { required },
        id_institucion_financiera: chequeRules,
        numero_cheque: chequeRules,
        a_nombre_de: chequeRules,
      },
    };
  },

  data: () => ({
    form: {
      fecha_hora: null,
      comprobante: null,
      comprobante_pago: null,
      // condiciones_pago: null,
      id_tipo_movimiento: null,
      justificacion: null,
      id_metodo_pago: null,
      monto: null,
      armotizacion: 0,
      retenciones: 0,
      desembolso: null,
      id_recepcion: null,
      id_institucion_financiera: null,
      numero_cheque: null,
      a_nombre_de: null,
    },

    config_calendar: {
      placement: "bottom",
      visibility: "focus",
    },

    tipoMovimiento: createLoadable([]),
    pago: createLoadable([]),
    documentos: createLoadable([]),
    modalVisualizarDocumento: false,
    visualizarDocumento: createLoadable(null),
    confirmacionCrearPago: false,
    formasPago: [],
    mostrarDocumentos: false,
  }),

  computed: {
    ...mapState(["asuetos", "serverTime"]),
    ...mapState("seguimientoOrden", ["pagoEtapa", "pagoEditable", "pagos"]),
    ...mapState("procesoCompra", ["etapasList"]),
    hoy() {
      return this.serverTime.clone();
    },
    esCheque() {
      return this.form.id_metodo_pago === 2;
    },
    esTrasferencia() {
      return this.form.id_metodo_pago === 3;
    },
    etapasListData() {
      const totalPagosPorRecepcion = new Map();

      // Calcular los totales de pagos por recepción
      this.pagos.forEach((pago) => {
        const idRecepcion = pago.id_recepcion;
        const montoPago = Number(pago.monto);

        if (totalPagosPorRecepcion.has(idRecepcion)) {
          // Si existe, actualizar el total de pagos sumando el monto del pago actual
          const totalAnterior = totalPagosPorRecepcion.get(idRecepcion);
          totalPagosPorRecepcion.set(idRecepcion, totalAnterior + montoPago);
        } else {
          // Si no existe, inicializar el total de pagos para esta recepción con el monto del pago actual
          totalPagosPorRecepcion.set(idRecepcion, montoPago);
        }
      });

      if (this.pagoEditable) {
        return this.etapasList;
      }

      const etapasListFiltrado = this.etapasList.filter((recepcion) => {        
        const idRecepcion = recepcion.id;
        const cantidadPagar = recepcion.cantidad_pagar;
        const factura = recepcion.RecepcionFactura?.id_estado_factura === 1
        const pagosParciales = recepcion.RecepcionFactura?.id_estado_factura === 2;
        const pagadoCompleto = recepcion?.EstadoRecepcion === 3;
        return (
          (!totalPagosPorRecepcion.has(idRecepcion) || totalPagosPorRecepcion.get(idRecepcion) !== +cantidadPagar) &&
          (pagosParciales || (factura && !pagadoCompleto))
        );
      });
      return etapasListFiltrado;
    },
    comprobantePagoErrors() {
      return new Validator(this.$v.form.comprobante_pago).detect().getResult();
    },
    comprobanteErrors() {
      return new Validator(this.$v.form.comprobante).detect().getResult();
    },
    justificacionErrors() {
      return new Validator(this.$v.form.justificacion).detect().getResult();
    },
    tipoMovimientoErrors() {
      return new Validator(this.$v.form.id_tipo_movimiento)
        .detect()
        .getResult();
    },
    formaPagoErrors() {
      return new Validator(this.$v.form.id_metodo_pago).detect().getResult();
    },
    montoErrors() {
      return new Validator(this.$v.form.monto).detect().getResult();
    },
    armotizacionErrors() {
      return new Validator(this.$v.form.armotizacion).detect().getResult();
    },
    rentecionesErrors() {
      return new Validator(this.$v.form.retenciones).detect().getResult();
    },
    desembolsoErrors() {
      return new Validator(this.$v.form.desembolso).detect().getResult();
    },
    // condicionesPagoErrors() {
    //   return new Validator(this.$v.form.condiciones_pago).detect().getResult();
    // },
    recepcionErrors() {
      return new Validator(this.$v.form.id_recepcion).detect().getResult();
    },
    fechaMovimientoErrors() {
      return new Validator(this.$v.form.fecha_hora).detect().getResult();
    },
    bancoErrors() {
      return new Validator(this.$v.form.id_institucion_financiera)
        .detect()
        .getResult();
    },
    numChequeErrors() {
      return new Validator(this.$v.form.numero_cheque).detect().getResult();
    },
    aNombreDeErrors() {
      return new Validator(this.$v.form.a_nombre_de).detect().getResult();
    },
  },
  methods: {
    ...mapActions("procesoCompra", ["getEtapasContrato", "getEtapasProgreso"]),
    ...mapMutations("seguimientoOrden", [
      "getPagoEtapa",
      "clearPagoEtapa",
      "getPagoEditable",
    ]),
    ...mapActions("seguimientoOrden", ["getPagos"]),
    async enviarPago() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      const formData = convertToFormData(
        removePropsFromObjectIfNil(this.form, Object.keys(this.form))
      );

      if (this.form.monto > +this.metricasPagos.monto_restante && !this.pagoEditable ) {
        this.temporalAlert({
          show: true,
          message: `El monto del pago no puede ser mayor al monto restante`,
          type: "error",
        });
        return;
      }

      toggleLoadable(this.pago);
      const action = !this.pagoEditable
        ? () =>
            this.services.ContratoService.postContratoPagos(
              this.form.id_recepcion,
              formData
            )
        : () =>
            this.services.ContratoService.putContratoPago(
              this.editarPago.id,
              formData
            );

      const { data } = await action(this.form.id);

      this.confirmacionCrearPago = false;
      setLoadableResponse(this.pago, data, {
        showAlertOnSuccess: true,
        skipOnSuccess: true,
      });
      this.closeModal();
      this.getEtapasContrato();
      this.$emit("reloadPagos");
    },
    async getBancos() {
      const { status, data } =
        await this.services.ContratoService.getInstitucionesFinancieras();
      if (status == 200) {
        this.bancos = data;
      }
    },
    async getTiposPagos() {
      const { status, data } =
        await this.services.ContratoService.getTiposPagos();
      if (status == 200) {
        this.formasPago = data;
      }
    },
    async cargarTipoMovimiento() {
      toggleLoadable(this.tipoMovimiento);
      const { data } =
        await this.services.SeguimientoContractual.obtenerTipoMovimiento();
      setLoadableResponse(this.tipoMovimiento, data);
    },
    formatearFecha(fecha) {
      return fecha
        ? moment(fecha).format("DD/MM/YYYY hh:mm A")
        : "Seleccione una fecha";
    },
    async montoRecepcion(idRecepcion) {
      const etapaEncontrada = this.etapasList.find(
        (etapa) => etapa.id === idRecepcion
      );
      if (etapaEncontrada) {
        const diferencia = (
          +etapaEncontrada.cantidad_pagar - etapaEncontrada.total_montos
        ).toFixed(2);
        this.form.monto = +diferencia;
        this.calcularDesembolso();
      }
    },
    calcularDesembolso() {
      const monto = Number(this.form.monto) ?? 0;
      const retenciones = Number(this.form.retenciones) ?? 0;
      const armotizacion = Number(this.form.armotizacion) ?? 0;
      this.form.desembolso = monto - retenciones - armotizacion;
      if (this.form.desembolso < 0) {
        this.form.desembolso = 0;
        this.temporalAlert({
          show: true,
          message: `El desembolso no puede ser negativo`,
          type: "error",
        });
      } else {
        this.form.desembolso = this.form.desembolso.toFixed(2);
      }
    },
    confirmarCrearPago() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      this.confirmacionCrearPago = true;
      this.$emit("close");
    },
    async obtenerDocumentos(idRecepcion){   
      if(!idRecepcion) return;
      
      toggleLoadable(this.documentos)
      const { data } = await this.services.SeguimientoContractual.obtenerDocumentosRecepcion(idRecepcion);
      this.mostrarDocumentos = true;
      setLoadableResponse(this.documentos, data)
    },
    limitarDecimales(field, value) {
      const newValueString = String(value);
      if (newValueString.includes(".")) {
        const decimalPart = newValueString.split(".")[1];
        if (decimalPart.length > 2) {
          this.$nextTick(() => {
            this.form[field] = parseFloat(value).toFixed(2);
          });
        }
      }
    },
    cancelarConfirmacion() {
      this.confirmacionCrearPago = false;
      this.getPagoEditable(false);
      this.limpiarFormulario();
    },
    limpiarFormulario() {
      this.form.fecha_hora = null;
      this.$v.form.$reset();
      this.$refs.form.reset();
      this.mostrarDocumentos = false;
    },
    closeModal() {
      this.$emit("close");
      this.clearPagoEtapa();
      this.getPagoEditable(false);
      this.limpiarFormulario();
    },
    async visualizarDocumentoRecepcion(adjunto, disk) {
      this.modalVisualizarDocumento = true;
      toggleLoadable(this.visualizarDocumento);
      const { data } = await this.services.SeguimientoContractual.visualizacionDocumentos({ adjunto, disk });
      setLoadableResponse(this.visualizarDocumento, data, { isFile: true });
    },
    handlePdfModalVisibility(visible) {
      this.modalVisualizarDocumento = visible;
    },
  },
  watch: {
    "form.monto": {
      handler(newValue) {
        this.limitarDecimales("monto", newValue);
        this.calcularDesembolso();
      },
      deep: true,
    },
    "form.armotizacion": {
      handler(newValue) {
        this.limitarDecimales("armotizacion", newValue);
        this.calcularDesembolso();
      },
      deep: true,
    },
    "form.retenciones": {
      handler(newValue) {
        this.limitarDecimales("retenciones", newValue);
        this.calcularDesembolso();
      },
      deep: true,
    },
    editarPago(value) {
      if (!value) return;

      this.form.monto = this.editarPago.monto;
      this.form.id_recepcion = this.editarPago.id_recepcion;
      this.form.fecha_hora = this.editarPago.fecha_hora;
      this.form.id_tipo_movimiento = this.editarPago.id_tipo_movimiento;
      this.form.justificacion = this.editarPago.justificacion;
      this.form.desembolso = this.editarPago.desembolso;
      this.form.armotizacion = this.editarPago.amortizacion_anticipo;
      this.form.retenciones = this.editarPago.otras_retenciones;
      this.form.id_institucion_financiera = this.editarPago.detalle?.id_institucion_financiera;
      this.form.a_nombre_de = this.editarPago.detalle?.a_nombre_de;
      this.form.numero_cheque = this.editarPago.detalle?.numero_cheque;
      this.form.id_metodo_pago = this.editarPago.id_metodo_pago;

      this.obtenerDocumentos(this.form.id_recepcion)
    },
  },
  created() {
    this.getBancos();
    this.getTiposPagos();
    this.cargarTipoMovimiento();
  },
};
</script>

<style lang="scss" scoped></style>
